const encodeData = false;
const ACCOUNT_KEY = "UserAccount";

export const debug = process.env.REACT_APP_DEBUG;

export const GetTypeDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  ) && window.innerWidth < 960
    ? "mobile"
    : "desktop";
};

export const toArray = (input) => {
  if (!input) return null;
  try {
    return JSON.parse(input);
  } catch (e) {
    return [];
  }
};

export const toString = (input) => {
  if (!input) return null;
  return JSON.stringify(input);
};

export const ConsoleLog = (value) => {
  if (debug) console.log(value);
};

export const SaveAccount = (data, remenberMe) => {
  if (remenberMe) {
    SaveStorage(ACCOUNT_KEY, data)
    return;
  }

  SetSession(ACCOUNT_KEY, data)
};

export const GetAccount = (key = "") => {
  let result = GetStorage(ACCOUNT_KEY);

  if (!result) return null;

  if (key === "") return result;

  return result[key];
};

export const SaveStorage = (key, data) => {
  if (encodeData) window.localStorage.setItem(btoa(key), btoa(toString(data)));
  else window.localStorage.setItem(key, toString(data));
};

export const GetStorage = (key) => {
  if (encodeData) {
    return toArray(atob(window.localStorage.getItem(btoa(key))));
  } else {
    return toArray(window.localStorage.getItem(key));
  }
};

export const SetValue = (key, data) => {
  SaveStorage(key, { key: data });
};

export const GetValue = (key) => {
  let data = GetStorage(key);

  if (!data) return null;

  return data["key"];
};

export const ExistsStorage = (key) => {
  return GetStorage(key) ? true : false;
};

export const SetSession = (key, data) => {
  if (encodeData) window.sessionStorage.setItem(btoa(key), btoa(toString(data)));
  else window.sessionStorage.setItem(key, toString(data));
};

export const GetSession = (key) => {
  if (encodeData) {
    return toArray(window.sessionStorage.getItem(btoa(key)));
  } else {
    return toArray(window.sessionStorage.getItem(key));
  }
};

export const RemoveSession = (key) => {
  if (encodeData) {
    window.sessionStorage.removeItem(btoa(key));
  } else {
    window.sessionStorage.removeItem(key);
  }
};

export const ExistsSession = (key) => {
  return GetSession(key) ? true : false;
};

export const Islogin = () => {
  let login = GetSession("login");
  if (login === undefined) return false;
  else if (login == "ls") return true;
  else return ExistsStorage(ACCOUNT_KEY);
};

export const Logout = () => {
  let visitorId = GetValue("visitorId");
  window.localStorage.clear();
  SetValue("visitorId", visitorId);
};

export function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

export function isEmpty(str) {
  return !str || str.length === 0;
}
