import React, { useEffect, useState } from "react";
import AccountContext from "./index";

import { Islogin, Logout, GetAccount, SaveAccount, SaveStorage } from "helpers/functions";

export default function AccountProvider({ children }) {
  const [isLogin, setIslogin] = useState(Islogin());
  const [account, setAccount] = useState(GetAccount());

  const onLogIn = (data, remenberMe) => {
    console.log(data, remenberMe);

    let account = {
      token: data.token,
      username: data.user.userName,
      fullname: data.user.name,
      title: data.name,
      defaultTeamName: data.user.defaultTeamName,
    };

    SaveAccount(account, remenberMe);
    SaveStorage("login", remenberMe ? "ls" : "ss");
    setIslogin(true);
    setAccount(account);
    window.location = "/enrollment";
  };

  const onLogOut = () => {
    Logout();
    setIslogin(false);
    window.location = "/";
  };

  return (
    <AccountContext.Provider
      value={{
        onLogIn,
        onLogOut,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}
