import { useContext, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Authentication layout components
import BasicLayout from "layouts/forms/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import LogoImage from "assets/images/logos/logo.png";
import CardMedia from "@mui/material/CardMedia";

import { checkUser } from "services/user";
import AccountContext from "context/account";
import Grid from "@mui/material/Grid";
import Footer from "../components/Footer";

export default function NotFound() {
  return (
    <BasicLayout image={bgImage}>
      <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
        <Card>
          <CardMedia
            src={LogoImage}
            component="img"
            title="logo"
            sx={{
              maxWidth: "100%",
              margin: 2,
              objectFit: "center",
              objectPosition: "center",
            }}
          />
        </Card>
      </Grid>

      <Footer light />
    </BasicLayout>
  );
}
