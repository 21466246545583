/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import SignatureCanvas from "react-signature-canvas";
import { useContext, useState } from "react";

import { useForm } from "react-hook-form";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/forms/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import LogoImage from "assets/images/logos/logo.png";
import CardMedia from "@mui/material/CardMedia";

import { checkUser } from "services/user";
import AccountContext from "context/account";
import Grid from "@mui/material/Grid";
import Footer from "../components/Footer";

function Basic() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { onLogIn } = useContext(AccountContext);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleSetRememberMe = () => {
    if (!isLoading) setRememberMe(!rememberMe);
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    checkUser(data["username"], data["password"], (data, ok) => {
      if (ok) {
        console.log("ok", ok);
        onLogIn(data, rememberMe);
      }
      setIsLoading(false);
    });
  };

  return (
    <BasicLayout image={bgImage}>
      <Grid item xs={8}>
        <Card>
          <CardMedia
            src={LogoImage}
            component="img"
            title="logo"
            sx={{
              maxWidth: "200px",
              margin: 2,
              objectFit: "center",
              objectPosition: "center",
            }}
          />
          <MDBox pt={4} pb={3} px={3} style={{ zIndex: 100 }}>
            <h1>ACA Health Insurance Consent Form</h1>
            <MDBox component="form" role="form">
              <br />
              <MDBox>
                This document is to protect your information and give permission for the USAPRODEX
                and its agent to submit it on your behalf. USAPRODEX Agent:{" "}
                <strong>vendor-name</strong> NPN 17129134
              </MDBox>
              <br />
              <br />
              <MDBox>
                I hereby give my consent for <strong>vendor-name</strong> to administer my
                healthcare needs through the marketplace. NO OTHER AGENT has permission to access,
                change my plan or alter my health insurance without my express written consent.
              </MDBox>
              <br />
              <h4>
                ACKNOWLEDGMENTC <strong style={{ color: "red" }}>*</strong>
              </h4>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch disabled={isLoading} checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;I AGREE: <strong>vendor-name</strong> can assist me with getting
                  health insurance.
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch disabled={isLoading} checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;I AGREE: <strong>vendor-name</strong> can assist me with getting
                  health insurance.
                </MDTypography>
              </MDBox>
              <br />
              <MDBox>
                By Clicking Submit <strong>vendor-name</strong> NPN 17129134 Will Represent You As
                Your Insurance Agent & Submit Your Information To Healthcare.gov
              </MDBox>
              <br />
              <h4>
                Signature <strong style={{ color: "red" }}>*</strong>
              </h4>
              <Grid container spacing={5} justifyContent="center" alignItems="center" height="100%">
                <Grid item xs={8}>
                  <SignatureCanvas
                    penColor="blue"
                    canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={5} justifyContent="center" alignItems="center" height="100%">
                <Grid item xs={4}>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      disabled={isLoading}
                      variant="gradient"
                      color="info"
                      onClick={handleSubmit(onSubmit)}
                      fullWidth
                    >
                      I AGREE
                    </MDButton>
                    {isLoading && (
                      <div style={{ textAlign: "center", width: "100%", paddingTop: "5px" }}>
                        <CircularProgress />
                      </div>
                    )}
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    </BasicLayout>
  );
}

export default Basic;
