import axios from "axios";
import alert from "helpers/alerts";

const URL_SERVICE = process.env.REACT_APP_BASE_API_URL;
const get = (route, headers, callback, customMsj) => {
  axios
    .get(`${URL_SERVICE}/${route}`, {
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((res) => {
      callback(res.data, true);
      return;
    })
    .catch((err) => {
      errAlert(err, callback, customMsj);
      return;
    });
};

const post = (route, data, headers, callback, customMsj) => {
  axios
    .post(`${URL_SERVICE}/${route}`, data, {
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((res) => {
      callback(res.data, true);
      return;
    })
    .catch((err) => {
      errAlert(err, callback, customMsj);
      return;
    });
};

const put = (route, data, headers, callback, customMsj) => {
  axios
    .put(`${URL_SERVICE}/${route}`, data, {
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((res) => {
      callback(res.data, true);
      return;
    })
    .catch((err) => {
      errAlert(err, callback, customMsj);
      return;
    });
};

const xdelete = (route, headers, callback, customMsj) => {
  axios
    .delete(`${URL_SERVICE}/${route}`, {
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((res) => {
      callback(res.data, true);
      return;
    })
    .catch((err) => {
      errAlert(err, callback, customMsj);
      return;
    });
};

const errAlert = (err, callback, customMsj) => {
  switch (err.code) {
    case "ERR_BAD_REQUEST": {
      //401;
      alert.confirm(customMsj || err.message, "error").then((result) => {
        callback(null, false);
      });

      break;
    }

    case "forbidden": {
      //403
      alert.confirm(customMsj || err.message, "info").then((result) => {
        callback(null, false);
      });
      break;
    }

    case "NOT_FOUND": {
      //404
      alert.confirm(customMsj || err.message, "warning").then((result) => {
        callback(null, false);
      });
      break;
    }
    default: {
      console.log(err);
      alert.confirm(customMsj || err.message, "error").then((result) => {
        callback(null, false);
      });
    }
  }
};

export { get, post, put, xdelete };
